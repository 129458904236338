<template>
<v-app>
  <div class="row">
    <div class="col-md-12">
      <b-form @submit.stop.prevent="formOnSubmit" enctype="multipart/form-data">
        <b-card-body title="Setelan slide banner">
          <b-form-group
            id="input-group-title"
            label="Judul Berita"
            label-for="input-title"
          >
            <b-form-input
              readonly
              id="input-title"
              v-model="form.title"
              placeholder="Judul Berita"
            ></b-form-input>
            <small class="text-danger">{{ error.title }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-excerpt"
            label="Kutipan"
            label-for="input-excerpt"
          >
            <b-form-input
              readonly
              id="input-excerpt"
              v-model="form.excerpt"
              placeholder="Kutipan"
            ></b-form-input>
            <small class="text-danger">{{ error.excerpt }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-author"
            label="Penulis"
            label-for="input-author"
          >
            <b-form-input
              readonly
              id="input-author"
              v-model="form.author"
              placeholder="Penulis"
            ></b-form-input>
            <small class="text-danger">{{ error.author }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-tags"
            label="Tag"
            label-for="input-tags"
          >
            <b-form-input
              readonly
              id="input-tags"
              v-model="form.tags"
              placeholder="Tag"
            ></b-form-input>
            <small class="text-danger">{{ error.tags }}</small>
          </b-form-group>

          <!-- Input Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >

          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="form.date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
             
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-trigger-modal-patient"
                v-model="form.date"
              >
              </b-form-input>
            </template>

            <v-date-picker locale="id" v-if="modal2" v-model="form.date">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
                OK
              </v-btn></v-date-picker
            >
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input text -->
          <b-form-group>
            <b-form-checkbox id="input-status" v-model="form.status" switch disabled>
              Aktifkan <b>({{ form.status ? "Ya" : "Tidak" }})</b>
            </b-form-checkbox>
          </b-form-group>

          <!-- Input Post Category Id -->
        <b-form-group
          id="input-group-post-category-id"
          label="Kategori Berita"
          label-for="input-post-category-id"
        >
          <treeselect
            v-model="form.post_category_id"
            :multiple="false"
            :options="websiste_post_categories"
          />
          <small class="text-danger">{{ error.post_category_id }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-link"
        >
          <label
            for="input-link"
            class="d-block"
          >URL Video Youtube <em class="text-muted">opsional</em></label>
          <b-form-input
            id="input-link"
            v-model="form.yt_link"
            placeholder="url video"
          ></b-form-input>
          <small class="text-danger">{{ error.yt_link }}</small>
        </b-form-group>


        <b-form-group
          id="input-group-post-category-id"
          label="Gambar"
          label-for="input-post-category-id"
        >          
          <div class="image-input-wrapper">
            <img
              :src="uploadedImageUrl == '' ? form.image : uploadedImageUrl"
              alt=""
            />
          </div>
        </b-form-group>
          <!-- <b-form-group
            id="input-group-image"
            label="Gambar"
            label-for="input-image"
          >
            <b-form-file
              v-model="form.image"
              placeholder="Upload Gambar"
              drop-placeholder="Letakkan file di sini..."
              @change="onFileUpload"
            ></b-form-file>
            <small class="text-danger">{{ error.image }}</small>
          </b-form-group> -->

        <!-- Content Input -->
        <b-form-group
          id="input-group-content"
          label="Konten:"
          label-for="input-content"
        >
          <!-- <div class="document-editor">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              @ready="onReady"
              v-model="form.content"
              :readonly="editorDisabled" :disabled="editorDisabled"
            ></ckeditor>
          </div>
          <small class="text-danger">{{ error.content }}</small> -->
          <div v-html="form.content"></div>
        </b-form-group>

          <!-- Button -->
          <b-button type="button" variant="primary" @click="btnEditOnClick(form.id)">Edit</b-button>
          <b-button class="ml-2" variant="danger" type="button" @click="btnCancelOnClick">
            Tutup
          </b-button>
        </b-card-body>
      </b-form>
    </div>
  </div>
</v-app>
</template>

<style scoped>
.image-input-wrapper {
  width: 500px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        title: "",
        excerpt: "",
        content: "",
        date: "",
        author: "",
        tags: "",
        post_category_id: null,
        image: "",
        status: "",
        yt_link:"",
      },
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        alignment: {
            options: [ 'left', 'right', 'center', 'justify' ]
        },
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      // Other
      websiste_post_categories: [],
      modal2: false,
      uploadedImageUrl: "",
    };
  },

  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.image = evt.target.files[0];
    },

    btnCancelOnClick(){
      this.$root.$emit('cancelAddWebsitePost');
    },

    async btnEditOnClick(id){
      let webPost = await module.get('website-posts/' + id)
      // If Data Not Found
      if (webPost == null) {
        // Redirect To List
        // this.$router.push('/donors')
      } else {
        this.$root.$emit('getWebsitePostById', webPost);
      }
    },

    async getPostCategoryOption() {
      let response = await module.setTreeSelectWebsitePost("website-post-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.websiste_post_categories = response.data;
      }
    },

    async formOnSubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === "status") {
          formData.append(key, value ? 1 : 0);
        }
        else {
          formData.append(key, value);
        }
      }
      let response = await module.submit(formData, this.route);
      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        this.uploadedImageUrl = ""
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit('updateWebsitePost');
      }
    },
  },

  mounted(){
      this.getPostCategoryOption()
  }
};
</script>

<style>
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>